<template>
  <div class="uk-container uk-margin-medium-top">
    <template v-if="! loading">
      <h1 :class="`mb-2 ${loading ? 'skeleton skeleton-text text-h1' : ''}`">
        {{ course.title }}
      </h1>
      <div class="uk-card uk-card-default" v-if="step == 0">
        <div class="uk-card-body" v-if="!loading">
          <h2>Instructions</h2>
          <ol>
            <li>Complete this exam to proceed to the next course</li>
            <li>
              There are <strong>{{ questions.length }} questions</strong>
            </li>
            <li>
              You will need to answer at least
              <strong>{{ course.pass_mark }} correctly</strong>
            </li>
            <li>You can retake this exam multiple times.</li>
          </ol>
          <a href="#" class="uk-button uk-button-primary" @click.prevent="step = 1"
            >Begin Now</a
          >
        </div>
      </div>
      <div class="uk-card uk-card-default" v-if="step == 1">
        <div v-for="(question, i) in questions" :key="`question-${i}`">
          <div class="uk-card-body" v-if="i == show_question">
            <h2>{{ i + 1 }}. {{ question.content }}</h2>
            <div class="uk-grid">
              <div
                class="uk-width-1-2@m uk-margin-small-top"
                v-for="(option, j) in question.options"
                :key="`option-${j}`"
              >
                <input
                  type="radio"
                  class="mr-2"
                  :id="`question-${i}-${j}`"
                  v-model="question.selected_option"
                  :value="option.id"
                  name="question"
                />
                <label :for="`question-${i}-${j}`">{{ option.content }}</label>
              </div>
            </div>
            <div class="uk-grid uk-margin-medium-top">
              <div class="uk-width-1-2@m">
                <a
                  href="#"
                  class="uk-button uk-button-primary"
                  @click.prevent="show_question--"
                  v-if="show_question > 0"
                  >Previous</a
                >
                <a
                  href="#"
                  class="uk-button uk-button-primary"
                  @click.prevent="step = 0"
                  v-if="show_question == 0"
                  >Back</a
                >
              </div>
              <div class="uk-width-1-2@m uk-text-right">
                <a
                  href="#"
                  class="uk-button uk-button-primary"
                  @click.prevent="show_question++"
                  v-if="show_question < questions.length - 1"
                  >Next</a
                >
                <a
                  href="#"
                  class="uk-button uk-button-primary"
                  @click.prevent="markExam()"
                  v-if="show_question == questions.length - 1"
                  >Complete</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-card uk-card-default" v-if="step == 2">
        <div class="uk-card-body">
          <h2>Your results</h2>
          <div v-if="!completed">
            <div uk-spinner></div>
          </div>
          <div v-if="completed">
            <div v-if="results.passed">
              <div class="uk-alert uk-alert-success">
                <h3>You Passed</h3>
                <h3>{{ results.score }}%</h3>
              </div>
              <a
                :href="$router.resolve({ name: 'guest.community' }).href"
                class="uk-button uk-button-primary"
                >Join the community</a
              >
            </div>
            <div v-if="!results.passed">
              <div class="uk-alert uk-alert-danger">
                <h3>You Failed</h3>
                <h3>{{ results.score }}%</h3>
              </div>
              <router-link
                :to="{ name: 'guest.academy.load', params: { id: 1 }}"
                class="uk-button uk-button-primary"
                >Review Videos</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      course: {},
      questions: [],
      step: 0,
      show_question: 0,
      results: {},
      completed: false,
      loading: true,
    };
  },

  methods: {
    fetch() {
      this.loading = true;
      this.$axios.get("/api/v1/guest/academy/exam").then((response) => {
        if(! response.data.exam) {
          window.location.href = this.$router.resolve({ name: 'guest.academy' }).href
        } else {
          this.course = response.data.course;
          this.questions = response.data.questions;
          this.loading = false;
        }
      });
    },

    markExam() {
      this.step = 2;
      this.completed = false;

      let data = this.questions.map((question) => {
        return {
          question_id: question.id,
          option_id: question.selected_option,
        };
      });

      this.$axios.post("/api/v1/guest/academy/exam", data).then((response) => {
        this.results = response.data.results;
        this.completed = true;
      });
    },
  },
};
</script>
